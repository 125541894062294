// MyGuestBook WebApp Front-End
// ReactJS 
//author : Mehd Kasmein

//----------------------------------------------------------------------------------------------------------------------------
// 1- configuration initiale

import React, { useState, useEffect} from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import './App.css';

function App() {
  // UUID pour suivre l'utilisateur
  const userId = localStorage.getItem('userId') || generateUUID();
  localStorage.setItem('userId', userId);

  function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & (0x3 | 0x8));
      return v.toString(16);
    });
  }

  //----------------------------------------------------------------------------------------------------------------------------
// 2 - Declaration des variables et paramètres

const [scrollPosition, setScrollPosition] = useState(0);
const [formData, setFormData] = useState({
  userId: userId,
  firstName: '',
  lastName: '',
  message: '',
  files: [],  // Liste de fichiers
});
const [isSending, setIsSending] = useState(false);
const [progress, setProgress] = useState(0);
const [uploadStatus, setUploadStatus] = useState('');
const [isButtonDisabled, setIsButtonDisabled] = useState(false);

const images = [
  'image1.jpg', 
  'image2.jpg',
  'image3.jpg',
  'image4.jpg',
  'image5.jpg',
  'image6.jpg', 
  'image10.jpg',
  'image8.jpg',
  'image9.jpg',
  'image7.jpg',
];

const [currentImage, setCurrentImage] = useState(0);

const scrollHeight = 100000;
const SEUILS = {
  seuil1: 300,
  seuil2: 500,
  seuil3: 1000,
  seuil4: 1500,
  seuil5: 2000,
  seuil6: 2600,
  seuil7: 3000,
  seuil8: 3500,
  seuil9: 4000,
  seuil10: 4500,
};
  //----------------------------------------------------------------------------------------------------------------------------
// 3 - Declaration des fonctions de gestion


// Début Envoi du message vers backend
const sendMessage = async () => {
    const messageData = {
      userId: formData.userId,
      firstName: formData.firstName,
      lastName: formData.lastName,
      message: formData.message,
    };

  try {
    const res = await axios.post('https://www.myguestbook.fr:8443/api/submit-message', messageData, {
      headers: { 'Content-Type': 'application/json' },
      timeout: 10000 // Timeout de 10 secondes
    });
    console.log('Message envoyé avec succès', res.data);
    return true;
  } catch (error) {
    if (error.code === 'ECONNABORTED') {
      console.error('Le délai de la requête a été dépassé');
      setUploadStatus('Échec de l\'envoi du message, veuillez réessayer');
    } else if (!error.response) {
      console.error('Aucune réponse du serveur');
      setUploadStatus('Impossible de contacter le serveur, vérifiez votre connexion internet');
    } else {
      console.error('Échec lors de l\'envoi du message', error);
      setUploadStatus('Échec de l\'envoi du message, veuillez réessayer');
    }
    return false;
  }
};

  // Fin Envoi du message vers backend

 // Début Envoi des fichiers
const sendFiles = async () => {
  if (formData.files && formData.files.length > 0) { 
    const formDataToSend = new FormData();
    formDataToSend.append('userId', formData.userId);
    formData.files.forEach(file => formDataToSend.append('files', file));

    try {
      const res = await axios.post('https://www.myguestbook.fr:8443/api/upload-files', formDataToSend, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        },
      });

      // Vérifiez le code de statut HTTP
      if (res.status >= 200 && res.status < 300) {
        console.log('Fichiers envoyés avec succès', res.data);
        setFormData(prevData => ({ ...prevData, files: [] }));
        resetform();
        setUploadStatus('Fichiers envoyés avec succès');
        return true; // Réussite
      } else if (res.status === 406) {
        console.error('Certains fichiers sont trop volumineux');
        setUploadStatus('Durée maximale autorisée pour une video est 5 minutes');
        return false; // Erreur, donc pas de succès
      } else {
        console.error('Erreur lors de l\'envoi des fichiers', res.data);
        setUploadStatus(`Échec lors de l'envoi des fichiers: ${res.data.error || 'Erreur inconnue'}`);
        return false; // Erreur, donc pas de succès
      }

    } catch (error) {
      // Gestion des erreurs dans le bloc catch
      if (error.response && error.response.status === 406) {
        console.error('Certains fichiers sont trop volumineux');
        setUploadStatus('Durée maximale autorisée pour une video est 5 minutes');
      } else {
        console.error('Échec lors de l\'envoi des fichiers', error);
        setUploadStatus('Échec lors de l\'envoi des fichiers, veuillez reessayer');
      }
      return false; // Erreur, donc pas de succès
    }
  } else {
    setUploadStatus('Envoi du message réussi');
    return false; // Aucun fichier à envoyer, donc pas de succès
  }
};
  // Fin Envoi des fichiers vers backend

    // Début Etat d'avancement d'envoi des fichiers

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    setUploadStatus('');

    const isMessageSent = await sendMessage();

    document.querySelector("#guestform > div:nth-child(1) > input[type=text]").value = "";
    document.querySelector("#guestform > div:nth-child(2) > input[type=text]").value = "";
    document.querySelector("#guestform > div:nth-child(3) > textarea").value="";
    setFormData({
      userId: userId,
      firstName: '',
      lastName: '',
      message: '',
    });

    if (isMessageSent) {
      const isFilesSent = await sendFiles();

      if (isFilesSent) {
        // Si l'envoi des fichiers et des messages est réussi, on réinitialise les champs du formulaire
        setFormData({
          userId: userId,
          firstName: '',
          lastName: '',
          message: '',
          files: [],
        });



        setUploadStatus('Envoi du message et des photos/vidéos réussis');
      }}
    // Fin Etat d'avancement d'envoi des fichiers

    // Début blocage du bouton envoi
    setIsSending(false);
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
      setProgress(0);
    }, 10000); //10sec
  };
    // Fin blocage du bouton envoi

    const resetform = () => { 
      document.getElementById("guestform").reset();
    }


  //----------------------------------------------------------------------------------------------------------------------------
  // 4 - Effets et événements
  
  function animatedOpacity(seuil, delay = 0) {
      return ({
        initial: { opacity: 0, top: `${94 - delay * 2}%` },
        animate: scrollPosition > SEUILS.seuil7 ? { opacity: 1 - ((scrollPosition - SEUILS.seuil7 - 200) / 200) } : { opacity: [0, 0.3, 0.8], top: [`${94 - delay * 2}%`, `${92 - delay * 2}%`] },
        transition: {
          duration: scrollPosition > SEUILS.seuil7 ? 1 : 0.25,
          delay: scrollPosition === 0 ? (2 + delay * 0.25) : scrollPosition > SEUILS.seuil7 ? 0 : delay * 0.25,
          repeat: Infinity,
          repeatType: 'reverse',
          repeatDelay: 0.5,
        },
      });
    }

  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = 'manual';
    console.log(userId);
    let isMounted = true;

    const handleScroll = () => {
      if (isMounted) {
        setScrollPosition(window.pageYOffset);
      }
    };

    window.addEventListener('scroll', handleScroll);

    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 2500); // Change d'image toutes les 2,5 secondes


    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.history.scrollRestoration = 'auto';
      isMounted = false;
      clearInterval(interval);
    };
  }, [userId,images.length]);


    //----------------------------------------------------------------------------------------------------------------------------
  // 5 - Rendu du composant
  
  return (

    <div>
      <motion.div
        style={{
          height: `${scrollHeight}px`,
        }}
      />

      <motion.div
            initial={{ opacity: 1}}
            animate={{ opacity: 0}}
            transition={{ duration: 0.5 , delay : 2}}
        style={{
          position: 'fixed',
          top: '42%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 20,
          backgroundPosition: 'center',
          backgroundColor: 'solid black',
          fontFamily : 'classy',
          color: 'white',
          fontSize : '4vw'
        }}
      >   
        Powered By
      </motion.div>
      <motion.div
            initial={{ opacity: 1}}
            animate={{ opacity: 0}}
            transition={{ duration: 0.5 , delay : 2}}
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 20,
          backgroundPosition: 'center',
          backgroundColor: 'solid black',
          fontFamily : 'bellena',
          color: 'white',
          fontSize : '6vw'
        }}
      >   
        MyGuestBook
      </motion.div>
      <motion.div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          height: '5000px',
          overflowY: 'scroll',
          backgroundColor: '#000000',
          backgroundPosition: 'center',
          filter: `blur(${Math.min(scrollPosition / 100, 10)}px)`,
          fontFamily : 'classy',
        }}
      />  
            
      <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 5 , delay : 1 }}
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 0,
          filter: `blur(${Math.min(scrollPosition / 3, 20)}px)`,
          marginBottom: 5000,
        }}
      >
       
       <div className="slideshow-container">
      {images.map((image, index) => (
        <div
          key={index}
          className={`slideshow-image ${index === currentImage ? 'active' : ''}`}
          style={{ backgroundImage: `url(${image})` }}
        ></div>
      ))}
    </div>
      </motion.div>
      {[...Array(3)].map((_, i) => (
        <motion.div
          key={i}
          className="square"
          {...animatedOpacity(_, i)}
          style={{
            opacity:  0,
            position: 'fixed',
            top: `${94 - i * 1.5}%`,
            left: '48%',
            transform: 'rotate(225deg)'
          }}
        />
      ))}

      <motion.h1
        style={{
          opacity: scrollPosition >= SEUILS.seuil6 + 300 ? Math.min(1 - ((scrollPosition - SEUILS.seuil6 - 300) / 200), 1) : scrollPosition / 200,
          position: 'fixed',
          top: '7%',
          left: '50%',
          textAlign: 'center',
          color: 'white',
          transform: 'translate(-50%, -50%)',
          textShadow: '0 0 10px black, 0 0 20px black, 0 0 30px black',
          width: 'calc(100% - 10px)',
          padding: '0 10px',
          boxSizing: 'border-box',
          fontSize : '6vw'
        }}
      >
        Chère Famille, Chers Amis
      </motion.h1>
      <motion.h2
        style={{
          opacity: scrollPosition >= SEUILS.seuil2
            ? scrollPosition >= SEUILS.seuil6 + 300
              ? Math.min(1 - ((scrollPosition - (SEUILS.seuil6 + 300)) / 200), 1)
              : (scrollPosition - SEUILS.seuil2) / 200
            : 0,
          position: 'fixed',
          top: '21%',
          left: '50%',
          textAlign: 'center',
          color: 'white',
          transform: 'translate(-50%, -50%)',
          textShadow: '0 0 10px black, 0 0 20px black, 0 0 30px black',
          width: 'calc(100% - 20px)',
          padding: '0 20px',
          boxSizing: 'border-box',
          fontSize : '4.5vw'
        }}
      >
        Si vous êtes sur cette page<br></br>c'est que vous êtes parmi nous<br></br>pour notre soirée de mariage
      </motion.h2>
      <motion.h2
        style={{
          opacity: scrollPosition >= SEUILS.seuil3
            ? scrollPosition >= SEUILS.seuil6 + 300
              ? Math.min(1 - ((scrollPosition - (SEUILS.seuil6 + 300)) / 200), 1)
              : (scrollPosition - SEUILS.seuil3) / 200
            : 0,
          position: 'fixed',
          top: '36%',
          left: '50%',
          textAlign: 'center',
          color: 'white',
          transform: 'translate(-50%, -50%)',
          textShadow: '0 0 10px black, 0 0 20px black, 0 0 30px black',
          width: 'calc(100% - 20px)',
          padding: '0 20px',
          boxSizing: 'border-box',
          fontSize : '4.5vw'
        }}
      >
        Merci du fond du cœur d'être là<br></br>pour célébrer avec nous notre amour<br></br>et partager avec nous ces moments de joie
      </motion.h2>
      <motion.h2
        style={{
          opacity: scrollPosition >= SEUILS.seuil4
            ? scrollPosition >= SEUILS.seuil6 + 300
              ? Math.min(1 - ((scrollPosition - (SEUILS.seuil6 + 300)) / 200), 1)
              : (scrollPosition - SEUILS.seuil4) / 200
            : 0,
          position: 'fixed',
          top: '55%',
          left: '50%',
          textAlign: 'center',
          color: 'white',
          transform: 'translate(-50%, -50%)',
          textShadow: '0 0 10px black, 0 0 20px black, 0 0 30px black',
          width: 'calc(100% - 20px)',
          padding: '0 20px',
          boxSizing: 'border-box',
          fontSize : '4.5vw'
        }}
      >
        Profitez bien de cette soirée<br></br>pour rigoler, vous amuser, danser, vous déhancher<br></br>bref profitez avec nous<br></br>au maximum de cette soirée
      </motion.h2>
      <motion.h2
        style={{
          opacity: scrollPosition >= SEUILS.seuil5
            ? scrollPosition >= SEUILS.seuil6 + 300
              ? Math.min(1 - ((scrollPosition - (SEUILS.seuil6 + 300)) / 200), 1)
              : (scrollPosition - SEUILS.seuil5) / 200
            : 0,
          position: 'fixed',
          top: '70%',
          left: '50%',
          color: 'white',
          transform: 'translate(-50%, -50%)',
          textShadow: '0 0 10px black, 0 0 20px black, 0 0 30px black',
          width: 'calc(100% - 20px)',
          padding: '0 20px',
          boxSizing: 'border-box',
          textAlign: 'center',
          fontSize : '4.5vw'
        }}
      >
        On vous aime
      </motion.h2>

      <motion.h2
        style={{
          opacity: scrollPosition >= SEUILS.seuil6
            ? scrollPosition >= SEUILS.seuil6 + 300
              ? Math.min(1 - ((scrollPosition - (SEUILS.seuil6 + 300)) / 200), 1)
              : (scrollPosition - SEUILS.seuil6) / 200
            : 0,
          position: 'fixed',
          top: '78%',
          left: '50%',
          textAlign: 'center',
          color: 'white',
          transform: 'translate(-50%, -50%)',
          textShadow: '0 0 10px black, 0 0 20px black, 0 0 30px black',
          width: 'calc(100% - 20px)',
          padding: '0 20px',
          boxSizing: 'border-box',
          fontSize : '5vw'
        }}
      >
        Imane & Mehdi <br></br> ❤
      </motion.h2>

      <motion.h1
        style={{
          opacity: scrollPosition >= SEUILS.seuil7+100 ? Math.min((scrollPosition - SEUILS.seuil7-100) / 200,1) : 0,
          position: 'fixed',
          top: '10%',
          left: '50%',
          textAlign: 'center',
          color: 'white',
          transform: 'translate(-50%, -50%)',
          textShadow: '0 0 10px black, 0 0 20px black, 0 0 30px black',
          width: 'calc(100% - 20px)',
          padding: '0 10px',
          boxSizing: 'border-box',
          fontSize : '5vw'
        }}
      >
        A tout moment de la soirée<br></br>Partagez avec nous <br></br><u>ici</u> <br></br>vos plus belles photos et videos
      </motion.h1>
      <motion.form
        id="guestform"
        onSubmit={handleSubmit}
        style={{
          opacity: scrollPosition >= SEUILS.seuil7 + 200 ? Math.min((scrollPosition - SEUILS.seuil7 - 200) / 200,1) : 0,
          visibility: scrollPosition >= SEUILS.seuil7 + 200 ? 'visible' : 'hidden',
          position: 'fixed',
          top: '55%',
          left: '50%',
          textAlign: 'center',
          color: 'black',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'rgba(255,255,255,0.5)',
          padding: '40px',
          zIndex: 10,
          borderRadius: '30px',
          boxShadow: '0 4px 20px white',
          maxWidth: '65vw',
          height: '100vw',
          margin: '0 auto',
          width: '300px',
          fontSize: '17px',
        }}
      >
{['Prénom', 'Nom', 'Message pour les mariés'].map((label, index) => (
  <motion.div
    key={index}
    style={{ marginBottom: '10px', opacity: scrollPosition >= SEUILS.seuil7 + 200 ? Math.min((scrollPosition - SEUILS.seuil7 - 200) / 200,1) : 0 ,visibility: scrollPosition >= SEUILS.seuil7 + 200 ? 'visible' : 'hidden'}}
  >
    <label>{label}</label>
    {label === 'Message pour les mariés' ? (
      <textarea
        value={formData.message}
        onChange={(e) => setFormData({ ...formData, message: e.target.value })}
        style={{
          backgroundColor: 'rgba(255,255,255,0.5)',
          borderRadius: '15px',
          width: '100%',
          padding: '8px',
          border: 'none',
          marginTop: '5px',
          height: '100px',  // Définir la hauteur ici
        }}
      />
    ) : (
      <input
        type="text"
        value={index === 0 ? formData.firstName : formData.lastName}
        onChange={(e) => setFormData({ ...formData, [index === 0 ? 'firstName' : 'lastName']: e.target.value })}
        required
        style={{
          backgroundColor: 'rgba(255,255,255,0.5)',
          borderRadius: '15px',
          width: '100%',
          padding: '8px',
          border: 'none',
          marginTop: '5px',
        }}
      />
    )}
  </motion.div>
))}

<motion.div
          style={{ marginBottom: '10px', opacity: scrollPosition >= SEUILS.seuil7 + 200 ? Math.min((scrollPosition - SEUILS.seuil7 - 200) / 200 ,1): 0 ,visibility: scrollPosition >= SEUILS.seuil7 + 200 ? 'visible' : 'hidden'}}
        >
          <label>Télécharger des photos/vidéos</label>
          <input
            type="file"
            multiple
            accept="image/*,video/*"
            onChange={(e) => setFormData({ ...formData, files: [...e.target.files] })}
            style={{ display: 'block', marginTop: '10px' }}
          />
        </motion.div>
      <motion.button
        type="submit"
        disabled={isButtonDisabled}
        style={{ visibility: scrollPosition >= SEUILS.seuil7 + 200 ? 'visible' : 'hidden',color: 'black',fontFamily : 'dreamcottage', border : 'none' ,marginTop: '20px',backgroundColor: 'rgba(255,255,255,0.5)',borderRadius :'15px',padding: '10px 20px', opacity: scrollPosition >= SEUILS.seuil7+200 ? Math.min((scrollPosition - SEUILS.seuil7-200) / 200,1) : 0, }}
        whileHover={!isButtonDisabled ? { scale: 1.1 } : {}}
        whileTap={!isButtonDisabled ? { scale: 0.9 } : {}}
      >
         {isSending ? 'Envoi en cours...' : 'Envoyer'}
        </motion.button>

        {isSending && (
          <div style={{ marginTop: '10px', width: '100%',fontSize: '10px' }}>
            <progress value={progress} max="100" style={{ width: '100%' }} />
            <p>Progression de l'envoi : {progress}%</p>
          </div>
        )}

        {!isSending && uploadStatus && (
          <p style={{ fontSize: '13px',marginTop: '10px', color: uploadStatus === 'Envoi du message réussi' ? 'green' : uploadStatus === 'Envoi du message et des photos/vidéos réussis' ? 'green' : 'red' ,textShadow: '0 0 10px white, 0 0 20px white, 0 0 30px white',}}>
            {uploadStatus}
          </p>
        )}
      
    </motion.form>

    </div>
  );
}

export default App;